import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import * as API from '../../services/api';

import Form from './Form';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
}));

export default function AdminUsersEdit(props) {
  const { uuid } = props;
  const classes = useStyles();

  const [adminUser, setAdminUser] = useState(null);

  useEffect(() => {
    if (!adminUser) {
      API.getAdminUser(uuid).then((newAdminUser) => {
        if (newAdminUser) {
          setAdminUser(newAdminUser);
        }
      });
    }
  }, [uuid, adminUser]);

  const onSubmit = async (values) => {
    await API.updateAdminUser(uuid, values.email, values.password);
    navigate(`/admin_users/${uuid}`);
  };

  if (!adminUser) {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">Update Admin User</Typography>
      </Paper>
      <Form isNew={false} initialValues={adminUser} onSubmit={onSubmit} />
    </>
  );
}
